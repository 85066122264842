.password-strength-meter {
  text-align: left;
}
.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
}
.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}
.password-strength-meter-label {
  font-size:  16px;
}
.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}
.password__label{margin-top:10px;margin-bottom: 0px;}
.strength-Weak::-webkit-progress-value {
  background-color: #ff0400;
}
.strength-Fair::-webkit-progress-value {
  background-color: #ffcc00;
}
.strength-Good::-webkit-progress-value {
  background-color: #00b3ff;
}
.strength-Strong::-webkit-progress-value {
  background-color: #03b11a;
}
.strength-Weak span {
  color: #ff0400;
}
.strength-Fair span {
  color: #ffcc00;
}
.strength-Good span {
  color: #00b3ff;
}
.strength-Strong span {
  color: #03b11a;
}