body
{
	margin: 0;
	padding: 0;
	height: 100vh;
	background: white;
	/* 11.01.24 linear-gradient(45deg, #404f66, #1a79de, #adb5bd ); */
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	font-family: sans-serif;
}
.loginBox
{
	/* position: absolute;
	top: 50%;
	left: 50%;
	float: left;
	transform: translate(-50%, -50%);
	transition: .5s; */
	width: 350px;
	box-sizing: border-box;
	background: transparent;
	box-shadow: 0 10px 20px rgba(0,0,0,.5);
	border-radius: 5px;
	padding: 0 40px 0 40px;
} 
/* .loginBox:hover
{
	box-shadow: 0 10px 20px rgba(0,0,0,0);
	background: rgba(255,255,255,1);
} */
.glass
{
	width: 100%;
	height: 100%;
	padding: 0 40px 0 40px;
	box-sizing: border-box;
	background: rgba(255,255,255,.1);
	border-radius: 5px;
	transition: .5s;
}
.glass:hover
{
	transform: translate(20px, 20px);
	box-shadow: 0 10px 20px rgba(0,0,0,.5);
}
.user
{
	margin: 0;
	padding: 0 0 0 80px;
	display: block;
	width: 200px;
	height: 100px;
	margin-bottom: 20px;
}
.logo{
	width: 60px;
	height: 60px;
}
h3
{
	margin: 0;
	padding: 0 0 20px;
	color: #262626;
	text-align: center;
}
.loginBox input
{
	width: 100%;
	margin-bottom: 20px;
}
.loginBox input[type="text"],
.loginBox input[type="password"]
{
	border: none;
	border-bottom: 2px solid #262626;
	outline: none;
	height: 40px;
	color: #262626;
	background: transparent;
	font-size: 16px;
	padding-left: 20px;
	box-sizing: border-box;
}
::placeholder
{
	color: rgba(0,0,0,.5);
}
.inputBox
{
	position: relative;
}
.inputBox span
{
	position: absolute;
	top: 10px;
	color: #262626;
}
.loginBox input[type="submit"]
{
	border: none;
	outline: none;
	height: 40px;
	font-size: 16px;
	background: linear-gradient(45deg, #40e0d0, #ff8c50, #ff0060);
	color: #fff;
	border-radius: 20px;
	cursor: pointer;
}
.loginBox input[type="submit"]:hover
{
	
	background: linear-gradient(45deg, #ff0060, #ff8c50, #40e0d0);
}

@media screen and (max-width: 988px)
{
	.loginBox
	{
		width: 350px;
		height: 550px;
	}
}
@media screen and (max-width: 640px)
{
	.loginBox
	{
		width: 350px;
		height: 550px;
		right: 10px;
	}
}
@media screen and (max-width: 320px)
{
	.loginBox
	{
		width: 320px;
		height: 550px;
		right: 10px;
	}
}
